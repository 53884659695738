import './index.scss'
import fullpage from 'fullpage.js'
import 'fullpage.js/dist/fullpage.min.css'
import Swiper from 'swiper/swiper-bundle.min'
import '../../assets/scripts/jquery.mousewheel'

$(document).ready(function(){
  if($(window).width() > 1024){
    initFullpage()
  }
})

//首页全屏滚动
var indexfullpage;
function initFullpage() {
  indexfullpage = new fullpage('#fullpage', {
    scrollingSpeed: 600,
    verticalCentered:false,
    css3: true,
    resize:true,
    autoScrolling: true,
    scrollBar: false,
    afterLoad: function (origin, destination, direction, trigger) {
      // console.log('afterLoad', origin.index, destination.index, direction, trigger)
      let i = destination.index;
      // 叶子显示
      if(i == 0){
        if(!$('.section1 .leaf-cont').hasClass('show')){
          $('.section1 .leaf-cont').addClass('show')
        }
      }else if(i == 1){
        if(!$('.section2 .leaf-cont').hasClass('show')){
          $('.section2 .leaf-cont').addClass('show')
        }
      }else if(i == 2){
        if(!$('.section3 .leaf-cont').hasClass('show')){
          $('.section3 .leaf-cont').addClass('show')
        }
        indexfullpage.setAllowScrolling(false);
      }
    },
    onLeave: function (origin, destination, direction,trigger) {
      // console.log('onLeave', origin.index, destination.index, direction,trigger)

      // 电脑端翻页时，叶子位移效果
      if(origin.index == 0 && destination.index == 1){
        console.log('下翻到第二屏')
        $('#fullpage .section1 .leaf-cont').addClass('next')
      }else if(origin.index == 1 && destination.index == 2){
        console.log('下翻到第三屏')
        $('#fullpage .section2 .leaf-cont').addClass('next')
      }else if(origin.index == 2 && destination.index == 1){
        console.log('上翻到第二屏')
        $('#fullpage .section2 .leaf-cont').removeClass('next')
      }else if(origin.index == 1 && destination.index == 0){
        console.log('上翻到第一屏')
        $('#fullpage .section1 .leaf-cont').removeClass('next')
      }

      let i = destination.index
      // 线上图片路径
      if(i == 0){
        $('body').css('background-image','url(/public/files/image/index_bg1.jpg)')
      }else if(i == 1){
        $('body').css('background-image','url(/public/files/image/index_bg2.jpg)')
      }else if(i == 2){
        $('body').css('background-image','url(/public/files/image/index_bg3.jpg)')
      }

      // 本地运行图片路径
      // if(i == 0){
      //   $('body').css('background-image','url(./image/index_bg1.jpg)')
      // }else if(i == 1){
      //   $('body').css('background-image','url(./image/index_bg2.jpg)')
      // }else if(i == 2){
      //   $('body').css('background-image','url(./image/index_bg3.jpg)')
      // }
    }
  });
}

//底部footer效果
$('#fullpage .section3').mousewheel(function(event, delta) {
  let footerH = $('.fixed-footer').outerHeight()
    if(delta > 0){//返回，上滚
      if(!$('.fixed-footer').hasClass('show')){
        indexfullpage.setAllowScrolling(true);
      }else{
        $('.fixed-footer').removeClass('show')
        $('.footer-tran').css('transform','none')
        $('#fullpage .section3 .leaf-cont').removeClass('next')
      }
    }else if(delta < 0){//下滚，显示footer
      if(!$('.fixed-footer').hasClass('show')){
        $('.fixed-footer').addClass('show')
        $('.footer-tran').css('transform','translateY('+ -footerH +'px)')
        $('#fullpage .section3 .leaf-cont').addClass('next')
      }
    }
    event.preventDefault();
});

if($(window).width() > 1024){
  $('.fixed-footer').mousewheel(function(event, delta) {
    if(delta > 0){//返回，上滚
      $('.fixed-footer').removeClass('show')
      $('.footer-tran').css('transform','none')
      $('#fullpage .section3 .leaf-cont').removeClass('next')
    }
    event.preventDefault();
  });
}

//首页banner
var indexswiper = new Swiper(".section1 .mySwiper", {
  loop: true,
  speed: 800,
  autoplay: {
    delay: 5000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".section1 .swiper-pagination",
    clickable: true,
  },
});

//首页第二屏
var swiper2 = new Swiper(".section2 .detail-cont .xscroll-cont .swiper", {
  speed: 800,
  mousewheel: {
    eventsTarged: '.section2 .detail-cont .cont',
  },
  // navigation: {
  //   nextEl: ".section2 .detail-cont .swiper-contral .swiper-button-next",
  //   prevEl: ".section2 .detail-cont .swiper-contral .swiper-button-prev",
  // },
  pagination: {
    el: ".section2 .detail-cont .swiper-contral .swiper-pagination",
    clickable: true,
    type : 'progressbar',
  },
  on: {
    init: function(swiper){
      $('.section2 .detail-cont .xscroll-tab .item').eq(this.activeIndex).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('.section2 .detail-cont .xscroll-tab .item').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
      if(this.activeIndex+1 == this.slides.length){
        $('.section2 .detail-cont .swiper-contral .swiper-btn .btn').addClass('back-btn')
      }else{
        $('.section2 .detail-cont .swiper-contral .swiper-btn .btn').removeClass('back-btn')
      }
    },
  },
});

//显示横向滚动区域
$('#fullpage .section2 .more-btn').on('click',function(){
  $(this).addClass('hide')
  $('#fullpage .section2').addClass('show-detail')
  $('#fullpage .section2 .detail-cont .btn').addClass('go-btn')
  $('#fullpage .section1 .leaf-cont,#fullpage .section2 .leaf-cont').addClass('trans')
  indexfullpage.setAllowScrolling(false);
  indexfullpage.setKeyboardScrolling(false);
})
//隐藏横向滚动区域
$('.section2 .detail-cont .swiper-contral .swiper-btn').on('click','.btn',function(){
  if($(this).hasClass('back-btn')){
    $('#fullpage .section2').removeClass('show-detail')
    $('#fullpage .section2 .detail-cont .btn').removeClass('back-btn').addClass('go-btn')
    $('#fullpage .section1 .leaf-cont,#fullpage .section2 .leaf-cont').removeClass('trans')
    $('#fullpage .section2 .more-btn').removeClass('hide')
    indexfullpage.setAllowScrolling(true);
    indexfullpage.setKeyboardScrolling(true);
    setTimeout(function(){
      swiper2.slideTo(0,0)
    },500)
  }else{
    swiper2.slideNext();
  }
})
